import {AuthGuard, AuthSdkModule} from '@12stz/auth-sdk';
import {provideBlocker} from '@12stz/ui-kit/organisms/blocker';
import {provideToast} from '@12stz/ui-kit/organisms/toast';
import {provideInterfaceSize} from '@12stz/ui-kit/tokens';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {RouterOutlet, provideRouter} from '@angular/router';
import {withNgxsReduxDevtoolsPlugin} from '@ngxs/devtools-plugin';
import {withNgxsFormPlugin} from '@ngxs/form-plugin';
import {withNgxsStoragePlugin} from '@ngxs/storage-plugin';
import {provideStore} from '@ngxs/store';
import {environment} from 'environments';
import {MainComponent} from './main.component';

const {auth: authServerUrl} = environment;
const authM = AuthSdkModule.forRoot({authServerUrl, clientId: 'feedback'});

@NgModule({
  bootstrap: [MainComponent],
  declarations: [MainComponent],
  imports: [BrowserModule, authM, RouterOutlet],
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideInterfaceSize('small'),
    provideBlocker(),
    provideToast(),
    provideRouter([
      {
        path: '',
        canActivate: [AuthGuard],
        data: {roles: ['access']},
        loadChildren: () =>
          import('../shell/shell.module').then((m) => m.ShellModule),
      },
    ]),
    provideStore(
      [],
      withNgxsFormPlugin(),
      withNgxsStoragePlugin({keys: ['feedback.limit', 'feedback.fastFilter']}),
      withNgxsReduxDevtoolsPlugin({disabled: environment.name === 'prod'})
    ),
  ],
})
export class MainModule {}
